import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Partners from 'components/shared/Partners'

const AllPartners = ({ className }) => {
  const {
    partners: {
      nodes: partners
    }
  } = useStaticQuery(graphql`{
    partners: allWordpressWpPartner(sort: {fields: date}) {
      nodes {
        ...PreviewPartnersFrag
      }
    }
  }`)
  return (
    <div className={`${className ? `${className}` : ``}`}>
      <Partners className="py-5" content={partners} />
    </div>
  )
}

export default AllPartners